import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './filtro.pipe';
import { FiltrosearchPipe } from './filtrosearch.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FiltroPipe, FiltrosearchPipe],
  exports: [FiltroPipe, FiltrosearchPipe, TranslateModule],
  imports: [CommonModule],
})
export class PipesModule {}

import { AjusteSuplente } from '../models/ajustesuplente';
import { Like } from './../models/like';
import { CacheService } from 'ionic-cache';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { UserService } from './user.service';

import { Ajuste } from '../models/ajuste';
import { AnyCnameRecord } from 'dns';
import { Aprendizaje } from '../models/aprendizaje';
import { Barrera } from '../models/barrera';
import { DBA } from '../models/dba';
import { Dimension } from '../models/dimension';
import { Discapacidad } from '../models/discapacidad';
import { Disgusto } from '../models/disgusto';
import { Experto } from '../models/experto';
import { Grado } from '../models/grado';
import { Gusto } from '../models/gusto';
import { Herramienta } from '../models/herramienta';
import { Herramientaind } from '../models/herramientaind';
import { Institucion } from '../models/institucion';
import { Keyword } from '../models/keyword';
import { Lenguaje } from '../models/lenguaje';
import { Objetivo } from '../models/objetivo';
import { Personalidad } from '../models/personalidad';
import { Preocupacion } from '../models/preocupacion';
import { Pregunta } from '../models/pregunta';
import { PreguntaIdenCausas } from '../models/preguntaIdenCausas';
import { PreguntaQuiz } from '../models/preguntaQuiz';
import { Quiz } from '../models/quiz';
import { Rating } from '../models/rating';
import { RespuestaQuiz } from '../models/respuestaQuiz';
import { RespuestaUsuarioQuiz } from '../models/respuestaUsuarioQuiz';
import { Reto } from '../models/reto';
import { Tema } from '../models/tema';
import { TemaComunidad } from '../models/temaComunidad';
import { Tip } from 'src/app/models/tips';
import { Video } from '../models/video';
import { Actividad } from '../models/actividad';
import { Materia } from '../models/materia';
import { TipoTerapia } from './../models/tipo-terapia';
import { Orientacion } from '../models/orientacion';
import { Evidencia } from '../models/evidencia';
import { Variable } from '../models/variable';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // endpoint user and password used to connect to webservices
  public UrlBase = 'https://b6q8k2pjtj.execute-api.us-east-2.amazonaws.com/'; // base url del servidor
  public Endpoint = this.UrlBase + 'dev/'; // endpoint de los servicios web
  public UrlBaseUpload = this.UrlBase + 'resources/img/person/'; // endpoint de ubicacion de las fotos
  public UrlBaseUploadVideos = this.Endpoint + 'insert_new_video'; // endpoint de servicio de subida de los videos

  public languaje = 'es';

  // Oficial
  public EndPointsDev = [
    {
      type: 'datos_basicos',
      url: 'https://lfqjo52dy0.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://u1gn4spebj.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'ajustes',
      url: 'https://xdder0cue8.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'evaluacion',
      url: 'https://5r1uq3ynye.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'perfil',
      url: 'https://xdder0cue8.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'experto',
      url: 'https://0n18qjv4d7.execute-api.us-east-2.amazonaws.com/dev/',
    },
    {
      type: 'comunidad',
      url: 'https://s44r5mkny7.execute-api.us-east-2.amazonaws.com/dev/',
    },
  ];

  public EndPointsUnit = [
    {
      type: 'datos_basicos',
      url: 'https://u8hslx03i6.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://ee9rnhpky9.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'ajustes',
      url: 'https://s150fcg165.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'evaluacion',
      url: 'https://qtr2rzqsb5.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'perfil',
      url: 'https://s150fcg165.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'experto',
      url: 'https://w8vynnf8ae.execute-api.us-east-2.amazonaws.com/unit/',
    },
    {
      type: 'comunidad',
      url: 'https://kjkq8jbgf4.execute-api.us-east-2.amazonaws.com/unit/',
    },
  ];

  public EndPointsDemo = [
    {
      type: 'datos_basicos',
      url: 'https://jbp0e2djfe.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://qv3rxg3lbf.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'ajustes',
      url: 'https://ubjm7bcvz7.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'evaluacion',
      url: 'https://eb86kohaoj.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'perfil',
      url: 'https://ubjm7bcvz7.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'experto',
      url: 'https://fg5jm0svjk.execute-api.us-east-2.amazonaws.com/demo/',
    },
    {
      type: 'comunidad',
      url: 'https://9701f1ztn8.execute-api.us-east-2.amazonaws.com/demo/',
    },
  ];

  public EndPointsProd = [
    {
      type: 'datos_basicos',
      url: 'https://849qwc4fph.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'registro_inst_est',
      url: 'https://4s9c7og3c3.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'ajustes',
      url: 'https://bojddckzy4.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'evaluacion',
      url: 'https://a6zbgnf1p7.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'perfil',
      url: 'https://bojddckzy4.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'experto',
      url: 'https://w21gxvvkrd.execute-api.us-east-2.amazonaws.com/prod/',
    },
    {
      type: 'comunidad',
      url: 'https://uqbwxdo436.execute-api.us-east-2.amazonaws.com/prod/',
    },
  ];

  /**
   * Constructor de la clase ApiProvider
   * @method contructor
   * @http  {Http} variable global objeto http
   */

  constructor(
    public http: HttpClient,
    private storage: Storage,
    private cache: CacheService,
    private user: UserService
  ) {
    this.user.isAuthenticated();
  }

  public modoDebug() {
    return environment.debug;
  }

  /**
   * Genera la url con datos para consultas tipo get
   * @method makeDataURL
   * @command  {string} comando a invocar
   * @data {string} datos adicionales en get
   * @return {Promise}
   */
  private makeDataURL(type: string, command: string, data: string): string {
    // Build a weather service URL using the command string and
    // location data that we have.
    var endpoint;
    if (environment.stage == 'prod')
      endpoint = this.EndPointsProd.filter(item => item.type === type);
    else if (environment.stage == 'demo')
      endpoint = this.EndPointsDemo.filter(item => item.type === type);
    else if (environment.stage == 'unit')
      endpoint = this.EndPointsUnit.filter(item => item.type === type);
    else endpoint = this.EndPointsDev.filter(item => item.type === type);
    let uri = endpoint[0].url + command;
    // let uri = this.Endpoint + command ;
    // Configure output for imperial (English) measurements
    uri += data;
    // Return the value
    return uri;
  }

  /**
   * Extrae los datos de respuesta en formato json
   * @method extractData
   * @res  {Response} respuesta de la ejecucion get
   * @return {JSON}
   */
  // 'Borrowed' from //https://angular.io/docs/ts/latest/guide/server-communication.html
  private extractData(res: Response) {
    // Convert the response to JSON format
    const body = res; // .json();
    // Return the data (or nothing)
    return body || {};
  }

  /**
   * Extrae los datos de respuesta en formato html
   * @method extractDataHTML
   * @res  {Response} respuesta de la ejecucion get
   * @return {HTML}
   */
  private extractDataHTML(res: Response) {
    // Convert the response to JSON format
    const body = res.text();
    // Return the data (or nothing)
    return body || {};
  }

  /**
   * Error handler para ejecuciones asincronas
   * @method extractData
   * @res  {Response} respuesta de la ejecucion get
   * @return {Promise}
   */
  // 'Borrowed' from //https://angular.io/docs/ts/latest/guide/server-communication.html
  private handleError(res: Response | any) {
    console.error('Entering handleError');
    console.error(res);
    return Promise.reject(res.error.error || res.message || res);
  }

  public getCachedData(url: string) {
    const cacheKey = url;
    const request = this.http.get(url);
    //    this.cache.removeItem(cacheKey);
    return this.cache
      .loadFromObservable(cacheKey, request)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**************************** DELETE *******************************/

  deleteAjuste(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/ajuste/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteAprendizaje(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/aprendizaje/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteBarrera(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/barrera/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteDBA(id: string) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/dba/', id);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteDimension(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/dimension/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteDiscapacidad(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/discapacidad/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteDisgusto(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/disgusto/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteEvidencia(idDBA: string, id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'dba/evidencia/',
      idDBA + '/' + id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteExperto(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/experto/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteGrado(id: string) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/grado/', id);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteGusto(id: string) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/gusto/', id);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteHerramienta(idAjuste: string, id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta/',
      idAjuste + '/' + id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteObjetivoV2(idAjuste: string, id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/objetivos/',
      idAjuste + '/' + id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteActividad(idObjetivo: string, id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'objetivo/actividadesObje/',
      idObjetivo + '/' + id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteAjusteSuplente(idObjetivo: string, id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajusteSuplente/',
      idObjetivo + '/' + id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteHerramientaind(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteTipoTerapia(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/tipo-terapia/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteAjusteObjetivo(idAjuste: string, idObjetivo: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/objetivo/',
      idAjuste + '/' + idObjetivo
    );
    if (this.modoDebug()) console.log(url);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteKeyword(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/keyword/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteMateria(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/materia/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteLenguaje(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/lenguaje/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deleteOrientacion(idEvidencia: string, id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'evidencia/orientacion/',
      idEvidencia + '/' + id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deletePersonalidad(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/personalidad/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deletePregunta(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/pregunta/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deletePreguntaIdenCausas(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/preguntaidencausas/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  deletePreocupacion(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/preocupacion/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteQuiz(id: string) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteQuestion(id: string) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/pregunta/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteTema(id: string) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/tema/', id);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteTip(id: string) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/tip/', id);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteVariable(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/variable/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteVideo(id: string) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/video/', id);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteObjetivo(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/objetivo/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /******************************QUERIES******************************************************************/

  getAjuste(id: string): Observable<Ajuste> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/ajuste/',
      id
    );
    return this.http
      .get<Ajuste>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getVariable(id: string): Observable<Variable> {
    const url: string = this.makeDataURL('datos_basicos', 'variable/', id);
    return this.http
      .get<Variable>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getAprendizaje(id: string): Observable<Aprendizaje> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/aprendizaje/',
      id
    );
    return this.http
      .get<Aprendizaje>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getBarrera(id: string): Observable<Barrera> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/barrera/',
      id
    );
    return this.http
      .get<Barrera>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getConsultarEvaluacion(uid: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/consultarEvaluacion/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarEvaluacionPrueba(uid: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/consultarEvaluacion/',
      uid + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getSeguimientoPiar(uid: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'piar/seguimiento-piar/',
      uid + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getCurricular(uid: string) {
    const url: string = this.makeDataURL('evaluacion', 'curricular/', uid);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getEvidenciasGradoMateria(grado: string, materia: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'planeacion/',
      grado + '/' + materia
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getPeriodoEvidencia(institucionId: string, id: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'periodoPlaneacion/',
      institucionId + '/' + id
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarEvaluaciones(uid: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/consultarEvaluaciones/',
      uid + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarSeguimientos(uid: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'piar/consultarSeguimiento/',
      uid + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarPiar(uid: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'piar/seguimiento-piar/',
      uid + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarEvaluacionesHistorial(uid: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/consultarEvaluacionesHistorial/',
      uid + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarEvaluacionesAll(uid: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/consultarAllEvaluaciones/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarExpertoVideo(uid: string) {
    const url: string = this.makeDataURL('datos_basicos', 'experto/', uid);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarLogros(uid: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'logros/tipos/',
      uid + '/' + this.languaje
    );
    if (this.modoDebug()) console.log(url);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarLogrosDoc(uid: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'logros-doc/tipos/',
      uid + '/' + this.languaje
    );
    if (this.modoDebug()) console.log(url);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarLogrosEstudiante(uid: string) {
    const url: string = this.makeDataURL('evaluacion', 'logros/', uid);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarLogrosDocente(uid: string) {
    const url: string = this.makeDataURL('evaluacion', 'logros-doc/', uid);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarLogrosReporte(uid: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/consultarLogros/',
      uid + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarPDF(uid: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/consultarAjustepdf/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getConsultarMonitoreoEstudiante(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'monitoreo/consultar/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarPerfilEstudiante(uid: string) {
    const url: string = this.makeDataURL('ajustes', 'perfil/consultar/', uid);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarEstudiante(id: string) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiante/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarImagenSkalo(id: string) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'imagen-skalo/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getDepartamentos() {
    const url = 'https://www.datos.gov.co/resource/xd7j-ypd4.json';
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getMucipiosPorDepartamento(departamento: string) {
    const url = `https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=${departamento}`;
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getTip(id: string): Observable<Tip> {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/tip/', id);
    return this.http.get<Tip>(url).pipe(retry(2), catchError(this.handleError));
  }
  getObjetivo(id: string): Observable<Objetivo> {
    const url: string = this.makeDataURL('datos_basicos', 'objetivo/', id);
    return this.http
      .get<Objetivo>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getDBA(id: string): Observable<DBA> {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/dba/', id);
    return this.http.get<DBA>(url).pipe(retry(2), catchError(this.handleError));
  }

  getDBAsFiltro(codigoMateria: string, codigoGrado: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'dbasFiltro/',
      codigoMateria + '/' + codigoGrado
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarPeriodosInstitucion(institucionId) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'institucion/periodos/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getDimension(id: string): Observable<Dimension> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/dimension/',
      id
    );
    return this.http
      .get<Dimension>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getDiscapacidad(id: string): Observable<Discapacidad> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/discapacidad/',
      id
    );
    return this.http
      .get<Discapacidad>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getDisgusto(id: string): Observable<Disgusto> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/disgusto/',
      id
    );
    return this.http
      .get<Disgusto>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getEsDocenteFamiliarOAdmin(userId) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'usuario/esdocentefamiliaroadmin/',
      userId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getEstudianteRelaciones(estudianteId) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiante/relaciones/',
      estudianteId
    );
    if (this.modoDebug()) console.log(url);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  // getEvidencia(id: string): Observable<Evidencia> {
  //   const url: string = this.makeDataURL('datos_basicos', 'entidad/evidencia/', id);
  //   return this.http.get<Evidencia>(url).pipe(
  //     retry(2),
  //     catchError(this.handleError)
  //   );
  // }
  getEvidenciaV2(dbaId: string, id: string): Observable<Evidencia> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'dba/evidencia/',
      dbaId + '/' + id
    );
    return this.http
      .get<Evidencia>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getEvidenciaPeriodo(dbaId: string, id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'dba/evidencia/',
      dbaId + '/' + id
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getExperto(id: string): Observable<Experto> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/experto/',
      id
    );
    return this.http
      .get<Experto>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getGrado(id: string): Observable<Grado> {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/grado/', id);
    return this.http
      .get<Grado>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getGusto(id: string): Observable<Gusto> {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/gusto/', id);
    return this.http
      .get<Gusto>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getHerramienta(idAjuste: string, id: string): Observable<Herramienta> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta/',
      idAjuste + '/' + id
    );
    return this.http
      .get<Herramienta>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getObjetivoV2(idAjuste: string, id: string): Observable<Objetivo> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/objetivos/',
      idAjuste + '/' + id
    );
    return this.http
      .get<Objetivo>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getActividad(idObjetivo: string, id: string): Observable<Actividad> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'objetivo/actividad/',
      idObjetivo + '/' + id
    );
    return this.http
      .get<Actividad>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getAjusteSuplente(idAjuste: string, id: string): Observable<AjusteSuplente> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/ajusteSuplente/',
      idAjuste + '/' + id
    );
    return this.http
      .get<AjusteSuplente>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getHerramientaind(id: string): Observable<Herramientaind> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind/',
      id
    );
    return this.http
      .get<Herramientaind>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getTipoTerapia(id: string): Observable<TipoTerapia> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/tipo-terapia/',
      id
    );
    return this.http
      .get<TipoTerapia>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getActividades(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'objetivo/actividades/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getAjustesSuplentes(id: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/ajustesSuplentes/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getInstitucion(id: string): Observable<Institucion> {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'institucion/',
      id
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get<Institucion>(url, options)
      .pipe(retry(2), catchError(this.handleError));
  }

  getKeyword(id: string): Observable<Keyword> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/keyword/',
      id
    );
    return this.http
      .get<Keyword>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getMateria(id: string): Observable<Materia> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/materia/',
      id
    );
    return this.http
      .get<Materia>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getLenguaje(id: string): Observable<Lenguaje> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/lenguaje/',
      id
    );
    return this.http
      .get<Lenguaje>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getListarAjustes(useCache = true) {
    const url: string = this.makeDataURL('datos_basicos', 'ajustes', '');
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarAjustesRelaciones(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajustes-relaciones',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarPreguntas() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/pregunta',
      ''
    );
    return this.getCachedData(url);
  }
  getListarPreguntaIdenCausas() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/preguntaidencausas',
      ''
    );
    return this.getCachedData(url);
  }
  getListarEvidencias() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/evidencia',
      ''
    );
    return this.getCachedData(url);
  }
  getListarExpertos() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/experto',
      ''
    );
    return this.getCachedData(url);
  }
  getListarTemas() {
    const url: string = this.makeDataURL('datos_basicos', 'entidades/tema', '');
    return this.getCachedData(url);
  }
  getListarKeywords() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/keyword',
      ''
    );
    return this.getCachedData(url);
  }
  getListarMaterias() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/materia',
      ''
    );
    return this.getCachedData(url);
  }
  getListarVideos() {
    // const pdata  = JSON.stringify(null);
    // console.log(pdata)
    // const url: string = this.makeDataURL('datos_basicos', 'entidades/video/'+pdata, '');
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/video',
      ''
    );
    return this.getCachedData(url);
  }
  getListarQuices() {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuestas',
      ''
    );
    return this.getCachedData(url);
  }
  getListarPreguntasQuiz() {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/preguntas',
      ''
    );
    return this.getCachedData(url);
  }
  getListarRespuestasQuiz() {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/respuestas',
      ''
    );
    return this.getCachedData(url);
  }
  getListarVideosAllInfo() {
    const url: string = this.makeDataURL('datos_basicos', 'videos', '');
    return this.getCachedData(url);
  }
  getListarVideosAllInfoDocente() {
    const url: string = this.makeDataURL('datos_basicos', 'videosDocente', '');
    return this.getCachedData(url);
  }
  getListarVideosAllInfoFamilia() {
    const url: string = this.makeDataURL('datos_basicos', 'videosFamilia', '');
    return this.getCachedData(url);
  }
  getListarHerramientaIndAllInfo() {
    const url: string = this.makeDataURL('datos_basicos', 'herramientaind', '');
    return this.getCachedData(url);
  }
  getListarTip() {
    const url: string = this.makeDataURL('datos_basicos', 'entidades/tip', '');
    return this.getCachedData(url);
  }
  getListarObjetivo(params = '') {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/objetivo',
      `/${params}`
    );
    return this.getCachedData(url);
  }
  getListarAprendizajes(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/aprendizaje',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarAprendizajesEtapa(etapa, useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades-etapa/aprendizaje/',
      etapa
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarBarreras(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/barrera',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }
  getGrados() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/grado',
      ''
    );
    return this.getCachedData(url);
  }
  getListarCiudades(pais: string, provincia: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/ciudad-' + pais + '-' + provincia,
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getListarDBAs() {
    const url: string = this.makeDataURL('datos_basicos', 'entidades/dba', '');
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getListarDimensiones() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/dimension',
      ''
    );
    return this.getCachedData(url);
  }
  getListarDificultadImplementacion() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/dificultad-implementacion-ajuste',
      ''
    );
    return this.getCachedData(url);
  }

  getListarDimensionesNueva(etapa: string, useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades-etapa/dimension/',
      etapa
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarDiscapacidades(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/discapacidad',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarDisgustos(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/disgusto',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarEstudiantes() {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiantes',
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarEstudiantesDocente() {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiantes/docente',
      ''
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarEstudiantesFamiliar() {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiantes/familiar',
      ''
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http.get(url, options).toPromise();
  }

  getListarEstudiantesInstitucion(institucionId, lastKey) {
    if (lastKey != '0') {
      lastKey = JSON.stringify(lastKey);
    }
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiantes/institucion/',
      institucionId + '/' + lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarEstudiantesInforme(institucionId, fecha, tipo) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/estudiantes/',
      institucionId + '/' + fecha + '/' + tipo
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarFechasDocente(docenteId) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'fechasdocente/',
      docenteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarGrados(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/grado',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarGradosEducativos(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/grado-educativo',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarGruposInstitucionales(lastKey = '0') {
    if (lastKey != '0') {
      if (typeof lastKey !== 'string') {
        lastKey = JSON.stringify(lastKey);
      }
    }
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'grupos-institucionales/usuario/',
      lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarGustos(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/gusto',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarHerramientas(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }
  getListarHerramientaind() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/herramientaind',
      ''
    );
    return this.getCachedData(url);
  }
  getListarTipoTerapia() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/tipo-terapia',
      ''
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getListarInstituciones(lastKey = '0') {
    if (lastKey != '0') {
      if (typeof lastKey !== 'string') {
        lastKey = JSON.stringify(lastKey);
      }
    }
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'instituciones/usuario/',
      lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarInstitucionesAdmin(lastKey) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'instituciones/',
      lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarLenguajes() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/lenguaje',
      ''
    );
    return this.getCachedData(url);
  }

  getListarMomentos() {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/momento',
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarOrientacionesEvidencia(evidenciaId: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'evidencia/orientaciones/',
      evidenciaId
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarPaises() {
    const url: string = this.makeDataURL('datos_basicos', 'entidades/pais', '');
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarPersonalidades(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/personalidad',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarPreocupaciones(useCache = true) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/preocupacion',
      ''
    );
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getListarProvincias(pais: string) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidades/provincia-' + pais,
      ''
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarUsuariosInstitucion(institucionId) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'usuarios/institucion/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarVariables(useCache = true) {
    const url: string = this.makeDataURL('datos_basicos', '/variables', '');
    if (useCache) return this.getCachedData(url);
    else {
      return this.http
        .get(url)
        .toPromise()
        .then(this.extractData)
        .catch(this.handleError);
    }
  }

  getObtenerUsuario(codigo: string) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'usuarioCodigo/',
      codigo
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getObtenerEstudiante(codigo: string) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudianteCodigo/',
      codigo
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getOrientacion(
    evidenciaId: string,
    orientacionId: string
  ): Observable<Orientacion> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'evidencia/orientacion/',
      evidenciaId + '/' + orientacionId
    );
    return this.http
      .get<Orientacion>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getPersonalidad(id: string): Observable<Personalidad> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/personalidad/',
      id
    );
    return this.http
      .get<Personalidad>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getPregunta(id: string): Observable<Pregunta> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/pregunta/',
      id
    );
    return this.http
      .get<Pregunta>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getPreguntaQuiz(id: string): Observable<PreguntaQuiz> {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/pregunta/',
      id
    );
    return this.http
      .get<PreguntaQuiz>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getPreguntasQuiz(id: string) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/preguntas/',
      id
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getPreguntaIdenCausas(id: string): Observable<PreguntaIdenCausas> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/preguntaidencausas/',
      id
    );
    return this.http
      .get<PreguntaIdenCausas>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getPreocupacion(id: string): Observable<Preocupacion> {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/preocupacion/',
      id
    );
    return this.http
      .get<Preocupacion>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  /**
   * Consulta todas los ajustes de un estudiante
   * @method getPreAjustesEstudiante
   * @return {Promise}
   */
  getPreAjustesEstudiante(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/generarPreAjustes/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getQuiz(id: string): Observable<Quiz> {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/',
      id
    );
    return this.http
      .get<Quiz>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getRating(uid: string) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/calificaciones/',
      uid
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getRecomendacionesEstudiante(uidEstudiante: string) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiante/recomendaciones/',
      uidEstudiante
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getRespuestaQuiz(id: string): Observable<RespuestaQuiz> {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/respuesta/',
      id
    );
    return this.http
      .get<RespuestaQuiz>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getRespuestasQuiz(id: string): Observable<RespuestaQuiz> {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/respuestas/',
      id
    );
    return this.http
      .get<RespuestaQuiz>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getReto(id: string): Observable<Reto> {
    const url: string = this.makeDataURL('comunidad', 'comunidad/reto/', id);
    return this.http
      .get<Reto>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getListarTemasComunidad() {
    const url: string = this.makeDataURL('comunidad', 'comunidad/tema', '');
    return this.getCachedData(url);
  }

  getTemaComunidad(id: string): Observable<TemaComunidad> {
    const url: string = this.makeDataURL('comunidad', 'comunidad/tema/', id);
    return this.http
      .get<TemaComunidad>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getRespuestasUsuarioQuiz(uid: string): Observable<RespuestaUsuarioQuiz> {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/respuestasusuario/',
      uid
    );
    return this.http
      .get<RespuestaUsuarioQuiz>(url)
      .pipe(retry(2), catchError(this.handleError));
  }

  getTema(id: string): Observable<Tema> {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/tema/', id);
    return this.http
      .get<Tema>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getUsuarioActual() {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'usuario/actual',
      ''
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVerAjustesEstudiante(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/verAjustes/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getConsultarActividades(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/consultarActividades/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVerObjetivosAjustesEstudiante(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/verObjetivosAjustes/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getVerDetalleAjuste(uid: string) {
    const url: string = this.makeDataURL('datos_basicos', 'ajuste/', uid);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVerDetalleDBA(uid: string) {
    const url: string = this.makeDataURL('datos_basicos', 'dba/', uid);
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVerPreguntas(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/verPreguntas/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVerPreguntasAdicionales(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/verPreguntasAdicionales/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVerPreguntasPerfil() {
    const url: string = this.makeDataURL('perfil', 'perfil/verPreguntas', '');
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getVerValoracionParcial(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/valoracionParcial/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVerAjusteSuplente(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/ajusteSuplente/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getValoracionEvaluacion(uid: string) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/consultarValoracionEvaluacion/',
      uid
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  getverReportesPeriodo(
    idEstudiante: string,
    fechaini: string,
    fechafin: string
  ) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/verReportesPeriodo/',
      idEstudiante + '/' + fechaini + '/' + fechafin
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getverReporte(idEstudiante: string, tipo: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/verReporte/',
      idEstudiante + '/' + tipo + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getverEvaluacionUnica(idEstudiante: string, fecha: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/verEvaluacionUnica/',
      idEstudiante + '/' + fecha
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVideo(id: string): Observable<Video> {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/video/', id);
    return this.http
      .get<Video>(url)
      .pipe(retry(2), catchError(this.handleError));
  }
  getverReporteId(idEstudiante: string) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/verReportes/',
      idEstudiante
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getVideosComunidadReto(idReto: string) {
    const url: string = this.makeDataURL(
      'comunidad',
      'comunidad/videos/reto/',
      idReto
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getListarRetos() {
    const url: string = this.makeDataURL('comunidad', 'comunidad/reto', '');
    return this.getCachedData(url);
  }

  getContarLikes(id) {
    const url: string = this.makeDataURL(
      'comunidad',
      'comunidad/like/registrar/',
      id
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getProfesoraSkalo(id) {
    const url: string = this.makeDataURL(
      'experto',
      'profesoraskalo/profesora-skalo/',
      id
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarProfesroa_post(uidusuario: any, data: any) {
    const url: string = this.makeDataURL('experto', 'profesoraskalo', '');

    const pdata = JSON.stringify({
      id: uidusuario,
      activo: data.activo,
      fecha: data.fecha,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // getcrearPDF(){
  //   const url: string = "http://localhost:3000/"
  //   return this.http.get(url).toPromise()
  //   .then(this.extractData)
  //   .catch(this.handleError);
  // }

  /******************************INSERTS******************************************************************/

  /**
   * Inserta los ajustes seleccionados para un estudiante
   * @method insertNewPista_post
   * @uidestudiante {any} uid del estudiante
   * @uiddocente {any} uid del docente
   * @formdata {any} formulario de registro de pista
   * @return {Promise} retorna objeto
   */
  confirmarAjustes_post(
    uidestudiante: any,
    uiddocente: any,
    ajustesseleccionados: any
  ) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/confirmarAjustes',
      ''
    );

    const pdata = JSON.stringify({
      imagenSkaloId: uidestudiante,
      docenteId: uiddocente,
      ajustes: ajustesseleccionados,
    });

    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  confirmarAjustesSuplentes_post(
    uidestudiante: any,
    uiddocente: any,
    ajustesseleccionados: any
  ) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/confirmarSuplentes',
      ''
    );

    const pdata = JSON.stringify({
      imagenSkaloId: uidestudiante,
      docenteId: uiddocente,
      ajustes: ajustesseleccionados,
    });

    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  confirmarObjetivos_post(
    uidestudiante: any,
    uiddocente: any,
    ajustesseleccionados: any
  ) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/confirmarObjetivos',
      ''
    );

    const pdata = JSON.stringify({
      imagenSkaloId: uidestudiante,
      docenteId: uiddocente,
      objetivos: ajustesseleccionados,
    });

    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  guardarActividad(imagenSkaloId: any, objetivoId: any, actividadId: any) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/guardarActividad',
      ''
    );
    const pdata = JSON.stringify({
      actividad: {
        imagenSkaloId: imagenSkaloId,
        objetivoId: objetivoId,
        actividadId: actividadId,
      },
    });

    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  deleteActividadImagen(
    imagenSkaloId: string,
    objetivoId: string,
    actividadId: string
  ) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/eliminarActividad/',
      imagenSkaloId + '/' + objetivoId + '/' + actividadId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .delete(url, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  eliminarValoracionParcial(uidestudiante: any) {
    const url: string = this.makeDataURL(
      'ajustes',
      'perfil/eliminar/',
      uidestudiante + '/PVA'
    );

    const pdata = JSON.stringify({
      pathParameters: {
        id: uidestudiante,
        filtro: 'PVA',
      },
    });

    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar ajuste
   * @param ajuste
   */
  grabarAjuste(ajuste: Ajuste) {
    const url: string = this.makeDataURL('datos_basicos', 'ajuste', '');
    const pdata = JSON.stringify({
      ajuste: {
        codigo: ajuste.attributes.codigo,
        descripcion: ajuste.attributes.descripcion,
        imagen: ajuste.attributes.imagen,
        momento: ajuste.attributes.momento,
        puntaje: ajuste.attributes.puntaje,
        video: ajuste.attributes.video,
        familia: ajuste.attributes.familia,
        docente: ajuste.attributes.docente,
        script: ajuste.attributes.script,
        material: ajuste.attributes.material,
        etapas: {
          pre: ajuste.attributes.etapaPre,
          pri: ajuste.attributes.etapaPri,
          bac: ajuste.attributes.etapaBac,
        },
        videos: {
          pre: ajuste.attributes.videoPre,
          pri: ajuste.attributes.videoPri,
          bac: ajuste.attributes.videoBac,
        },
        scripts: {
          pre: ajuste.attributes.scriptPre,
          pri: ajuste.attributes.scriptPri,
          bac: ajuste.attributes.scriptBac,
        },
        grados: ajuste.attributes.grados,
        locales: ajuste.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar ajuste
   * @param ajuste
   */
  grabarAjusteidioma(ajuste: Ajuste) {
    const url: string = this.makeDataURL('datos_basicos', 'ajuste', '');
    const pdata = JSON.stringify({
      ajuste: {
        codigo: ajuste.attributes.codigo,
        descripcion: ajuste.attributes.descripcion,
        imagen: ajuste.attributes.imagen,
        momento: ajuste.attributes.momento,
        puntaje: ajuste.attributes.puntaje,
        video: ajuste.attributes.video,
        familia: ajuste.attributes.familia,
        docente: ajuste.attributes.docente,
        script: ajuste.attributes.script,
        etapas: ajuste.attributes.etapas,
        videos: ajuste.attributes.videos,
        scripts: ajuste.attributes.scripts,
        grados: ajuste.attributes.grados,
        locales: ajuste.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarDBA(dba: DBA) {
    const url: string = this.makeDataURL('datos_basicos', 'dba', '');
    const pdata = JSON.stringify({
      dba: {
        id: dba.id,
        nombre: dba.attributes.nombre,
        descripcion: dba.attributes.descripcion,
        prioridad: dba.attributes.prioridad,
        materiaId: dba.attributes.materiaId,
        gradoEducativoId: dba.attributes.gradoEducativoId,
        materiaCodigo: dba.attributes.materiaCodigo,
        gradoEducativoCodigo: dba.attributes.gradoEducativoCodigo,
        codigo: dba.attributes.codigo,
        locales: dba.attributes.locales
          ? dba.attributes.locales
          : [
              {
                locale: 'en',
                nombre: dba.attributes.nombreEn,
                descripcion: dba.attributes.descripcionEn,
              },
            ],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar DBA idiomas
   * @param DBA
   */
  grabarDBAidiomas(dba: DBA) {
    const url: string = this.makeDataURL('datos_basicos', 'dba', '');
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      dba: {
        id: dba.id,
        nombre: dba.attributes.nombre,
        descripcion: dba.attributes.descripcion,
        prioridad: dba.attributes.prioridad,
        materiaId: dba.attributes.materiaId,
        gradoEducativoId: dba.attributes.gradoEducativoId,
        materiaCodigo: dba.attributes.materiaCodigo,
        gradoEducativoCodigo: dba.attributes.gradoEducativoCodigo,
        codigo: dba.attributes.codigo,
        locales: dba.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar pregunta
   * @param pregunta
   */
  //  grabarEvidencia(evidencia: Evidencia){
  //   const url: string = this.makeDataURL('datos_basicos', 'entidad/evidencia', '');
  //   // console.log(herramientaind)
  //   const pdata = JSON.stringify({
  //     entidad: {
  //       codigo: evidencia.attributes.codigo,
  //       nombre: evidencia.attributes.nombre,
  //       descripcion: evidencia.attributes.descripcion,
  //       locales: evidencia.attributes.locales,
  //       nombreEn: evidencia.attributes.nombreEn,
  //       descripcionEn: evidencia.attributes.descripcionEn
  //     }
  //   });
  //   return this.http.post(url, pdata)
  //       .toPromise()
  //       .then(this.extractData)
  //       .catch(this.handleError);
  // }
  grabarEvidenciaV2(evidencia: Evidencia, dba: DBA) {
    const url: string = this.makeDataURL('datos_basicos', 'dba/evidencia', '');

    const pdata = JSON.stringify({
      dba: {
        id: dba.id,
      },
      evidencia: {
        id: evidencia.attributes.id,
        dbaId: dba.id,
        nombre: evidencia.attributes.nombre,
        descripcion: evidencia.attributes.descripcion,
        prioridad: evidencia.attributes.prioridad,
        locales: evidencia.attributes.locales
          ? evidencia.attributes.locales
          : [
              {
                locale: 'en',
                nombre: dba.attributes.nombreEn,
                descripcion: dba.attributes.descripcionEn,
              },
            ],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  grabarDbaEvidenciaIdiomas(dbaId: any, evidencia: Evidencia) {
    const url: string = this.makeDataURL('datos_basicos', 'dba/evidencia', '');
    const pdata = JSON.stringify({
      dba: {
        id: dbaId,
      },
      evidencia: {
        id: evidencia.attributes.id,
        dbaId: dbaId,
        nombre: evidencia.attributes.nombre,
        descripcion: evidencia.attributes.descripcion,
        prioridad: evidencia.attributes.prioridad,
        locales: evidencia.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar pregunta
   * @param pregunta
   */
  grabarPregunta(pregunta: Pregunta) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/pregunta',
      ''
    );
    // console.log(pregunta)
    const pdata = JSON.stringify({
      entidad: {
        codigo: pregunta.attributes.codigoFinal,
        descripcion: pregunta.attributes.descripcion,
        codigoDimension: pregunta.attributes.codigoDimension,
        puntaje: pregunta.attributes.puntaje,
        respuesta: pregunta.attributes.respuesta,
        preguntaAvance: pregunta.attributes.preguntaAvance,
        etapas: {
          pre: pregunta.attributes.etapaPre,
          pri: pregunta.attributes.etapaPri,
          bac: pregunta.attributes.etapaBac,
        },
        orden: pregunta.attributes.orden,
        grados: pregunta.attributes.grados,
        escala: pregunta.attributes.escala,
        locales: [
          {
            locale: 'en',
            descripcion: pregunta.attributes.descripcionEn,
            preguntaAvance: pregunta.attributes.preguntaAvanceEn,
          },
        ],
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar experto
   * @param experto
   */
  grabarExperto(experto: Experto) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/experto',
      ''
    );
    // console.log(experto)
    const pdata = JSON.stringify({
      entidad: {
        codigo: experto.attributes.codigo,
        descripcion: experto.attributes.descripcion,
        nombre: experto.attributes.nombre,
        // docente:experto.data.docente,
        // familia:experto.data.familia,
        imagen: experto.attributes.imagen,
        titulo: experto.attributes.titulo,
        videoPresentacion: experto.attributes.videoPre,
        locales: experto.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar experto
   * @param experto
   */
  grabarExpertoIdioma(experto: Experto) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/experto',
      ''
    );
    // console.log(experto)
    const pdata = JSON.stringify({
      entidad: {
        codigo: experto.attributes.codigo,
        descripcion: experto.attributes.descripcion,
        nombre: experto.attributes.nombre,
        // docente:experto.data.docente,
        // familia:experto.data.familia,
        imagen: experto.attributes.imagen,
        titulo: experto.attributes.titulo,
        videoPresentacion: experto.attributes.videoPre,
        locales: experto.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar tema
   * @param tema
   */
  grabarTema(tema: Tema) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/tema', '');
    // console.log(tema)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tema.attributes.codigo,
        descripcion: tema.attributes.descripcion,
        nombre: tema.attributes.nombre,
        docente: tema.attributes.docente,
        familia: tema.attributes.familia,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar keyword
   * @param keyword
   */
  grabarKeyword(keyword: Keyword) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/keyword',
      ''
    );
    // console.log(keyword)
    const pdata = JSON.stringify({
      entidad: {
        codigo: keyword.attributes.codigo,
        descripcion: keyword.attributes.descripcion,
        nombre: keyword.attributes.nombre,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar materia
   * @param materia
   */
  grabarMateria(materia: Materia) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/materia',
      ''
    );
    // console.log(keyword)
    const pdata = JSON.stringify({
      entidad: {
        codigo: materia.attributes.codigo,
        descripcion: materia.attributes.descripcion,
        nombre: materia.attributes.nombre,
        locales: materia.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarMateriaIdioma(materia: Materia) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/materia',
      ''
    );
    // console.log(keyword)
    const pdata = JSON.stringify({
      entidad: {
        codigo: materia.attributes.codigo,
        descripcion: materia.attributes.descripcion,
        nombre: materia.attributes.nombre,
        locales: materia.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  grabarOrientacion(idEvidencia: string, orientacion: Orientacion) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'evidencia/orientacion',
      ''
    );
    const pdata = JSON.stringify({
      evidencia: {
        id: idEvidencia,
      },
      orientacion: {
        id: orientacion.attributes.id,
        evidenciaId: idEvidencia,
        titulo: orientacion.attributes.titulo,
        nombre: orientacion.attributes.nombre,
        descripcion: orientacion.attributes.descripcion,
        prioridad: orientacion.attributes.prioridad,
        archivos_anexos: orientacion.attributes.archivos_anexos,
        links: orientacion.attributes.links,
        clasificacion: orientacion.attributes.clasificacion,
        locales: orientacion.attributes.locales
          ? orientacion.attributes.locales
          : [
              {
                locale: 'en',
                titulo: orientacion.attributes.tituloEn,
                nombre: orientacion.attributes.nombreEn,
                descripcion: orientacion.attributes.descripcionEn,
              },
            ],
      },
    });
    console.warn('Grabando orientacion con:', pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  grabarOrientacionIdiomas(idEvidencia: string, orientacion: Orientacion) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'evidencia/orientacion',
      ''
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      evidencia: {
        id: idEvidencia,
      },
      orientacion: {
        id: orientacion.attributes.id,
        evidenciaId: idEvidencia,
        titulo: orientacion.attributes.titulo,
        nombre: orientacion.attributes.nombre,
        descripcion: orientacion.attributes.descripcion,
        prioridad: orientacion.attributes.prioridad,
        archivos_anexos: orientacion.attributes.archivos_anexos,
        links: orientacion.attributes.links,
        clasificacion: orientacion.attributes.clasificacion,
        locales: orientacion.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   *
   *
   * Grabar video
   * @param video
   */
  grabarVideo(video: Video) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/video', '');
    // console.log(video)
    const pdata = JSON.stringify({
      entidad: {
        codigo: video.attributes.codigo,
        descripcion: video.attributes.descripcion,
        nombre: video.attributes.nombre,
        experto: video.attributes.experto,
        temas: video.attributes.temas,
        keywords: video.attributes.keywords,
        video: video.attributes.video,
        locales: video.attributes.locales,
        familia: video.attributes.familia,
        docente: video.attributes.docente,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar video
   * @param video
   */
  grabarVideoidioma(video: Video) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/video', '');
    // console.log(video)
    const pdata = JSON.stringify({
      entidad: {
        codigo: video.attributes.codigo,
        descripcion: video.attributes.descripcion,
        nombre: video.attributes.nombre,
        experto: video.attributes.experto,
        temas: video.attributes.temas,
        keywords: video.attributes.keywords,
        video: video.attributes.video,
        locales: video.attributes.locales,
        familia: video.attributes.familia,
        docente: video.attributes.docente,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar quiz
   * @param quiz
   */
  grabarQuiz(quiz: Quiz) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta',
      ''
    );
    // console.log(quiz)
    const pdata = JSON.stringify({
      encuesta: {
        codigo: quiz.attributes.codigo,
        descripcion: quiz.attributes.descripcion,
        video: quiz.attributes.video,
        locales: quiz.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar rating
   * @param rating
   */
  grabarRating(rating: Rating) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/calificacion',
      ''
    );
    // console.log(rating)
    const pdata = JSON.stringify({
      rating: {
        codigo: rating.attributes.codigo,
        video: rating.attributes.video,
        usuario: rating.attributes.usuario,
        calificacion: rating.attributes.calificacion,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar preguntaQuiz
   * @param preguntaQuiz
   */
  grabarPreguntaQuiz(preguntaQuiz: PreguntaQuiz) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/pregunta',
      ''
    );
    // console.log(preguntaQuiz)
    const pdata = JSON.stringify({
      pregunta: {
        codigo: preguntaQuiz.attributes.codigo,
        descripcion: preguntaQuiz.attributes.descripcion,
        encuesta: preguntaQuiz.attributes.encuesta,
        locales: preguntaQuiz.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar respuestaQuiz
   * @param respuestaQuiz
   */
  grabarRespuestaQuiz(respuestaQuiz: RespuestaQuiz) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/encuesta/respuesta',
      ''
    );
    // console.log(respuestaQuiz)
    const pdata = JSON.stringify({
      respuesta: {
        codigo: respuestaQuiz.attributes.codigo,
        respuestas: respuestaQuiz.attributes.respuestas,
        pregunta: respuestaQuiz.attributes.pregunta,
        estado: respuestaQuiz.attributes.estado,
        locales: respuestaQuiz.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar respuestasUsuarioQuiz
   * @param respuestasUsuarioQuiz
   */
  grabarRespuestaUserQuiz(respuestaUsuarioQuiz: RespuestaUsuarioQuiz) {
    const url: string = this.makeDataURL(
      'experto',
      'videoexperto/respuestausuario',
      ''
    );
    // console.log(respuestaUsuarioQuiz)
    const pdata = JSON.stringify({
      respuesta: {
        codigo: respuestaUsuarioQuiz.attributes.codigo,
        video: respuestaUsuarioQuiz.attributes.video,
        usuario: respuestaUsuarioQuiz.attributes.usuario,
        estado: respuestaUsuarioQuiz.attributes.estado,
        aprobado: respuestaUsuarioQuiz.attributes.aprobado,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar aprendizaje
   * @param aprendizaje
   */
  grabarAprendizaje(aprendizaje: Aprendizaje) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/aprendizaje',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: aprendizaje.attributes.codigo,
        descripcion: aprendizaje.attributes.descripcion,
        nombre: aprendizaje.attributes.nombre,
        orden: aprendizaje.attributes.orden,
        etapas: {
          pre: aprendizaje.attributes.etapaPre,
          pri: aprendizaje.attributes.etapaPri,
          bac: aprendizaje.attributes.etapaBac,
        },
        locales: [
          {
            locale: 'en',
            nombre: aprendizaje.attributes.nombreEn,
            descripcion: aprendizaje.attributes.descripcionEn,
          },
        ],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarAsignarFamilar_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarAsignarFamiliar_post(datos: any) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiante/familiar',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarAsignarDocente_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarAsignarDocente_post(datos: any) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiante/docente',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar barrera de aprendizaje
   * @param barrera
   */
  grabarBarrera(barrera: Barrera) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/barrera',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: barrera.attributes.codigo,
        nombre: barrera.attributes.nombre,
        locales: [{ locale: 'en', nombre: barrera.attributes.nombreEn }],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar dimension
   * @param dimension
   */
  grabarDimension(dimension: Dimension) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/dimension',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: dimension.attributes.codigo,
        descripcion: dimension.attributes.descripcion,
        nombre: dimension.attributes.nombre,
        orden: dimension.attributes.orden,
        etapas: {
          pre: dimension.attributes.etapaPre,
          pri: dimension.attributes.etapaPri,
          bac: dimension.attributes.etapaBac,
        },
        locales: [
          {
            locale: 'en',
            nombre: dimension.attributes.nombreEn,
            descripcion: dimension.attributes.descripcionEn,
          },
        ],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar discapacidad
   * @param discapacidad
   */
  grabarDiscapacidad_post(discapacidad: Discapacidad) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/discapacidad',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        nombre: discapacidad.attributes.nombre,
        codigo: discapacidad.attributes.codigo,
        locales: [{ locale: 'en', nombre: discapacidad.attributes.nombreEn }],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar disgusto
   * @param disgusto
   */
  grabarDisgusto(disgusto: Disgusto) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/disgusto',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: disgusto.attributes.codigo,
        descripcion: disgusto.attributes.descripcion,
        locales: disgusto.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  grabarDisgustoIdioma(disgusto: Disgusto) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/disgusto',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: disgusto.attributes.codigo,
        descripcion: disgusto.attributes.descripcion,
        locales: disgusto.attributes.locales,
      },
    });
    console.log(pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarEstudiante_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarEstudiante_post(datos: any) {
    const url: string = this.makeDataURL('registro_inst_est', 'estudiante', '');
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   *
   * @param uidestudiante
   * @param uiddocente
   * @param fecha
   * @param comentario
   * @param ajustessel
   */
  grabarEvaluacion_post(
    uidestudiante: any,
    uiddocente: any,
    fecha: any,
    comentario: any,
    ajustessel: any
  ) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/grabarEvaluacion',
      ''
    );

    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: uiddocente,
      fecha,
      observaciones: comentario,
      ajustes: ajustessel,
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   *
   * @param uidestudiante
   * @param uiddocente
   * @param fecha
   * @param comentario
   * @param ajustessel
   */
  grabarEvaluacionDimension_post(
    uidestudiante: any,
    uiddocente: any,
    fecha: any,
    comentario: any,
    preguntasFinales: any,
    ajustes: any,
    estado: any
  ) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/grabarEvaluacion',
      ''
    );

    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: uiddocente,
      fecha,
      observaciones: comentario,
      dimensiones: preguntasFinales,
      ajustes: ajustes,
      estado: estado,
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar Grado Escolar
   * @param grado
   */
  grabarGrado_post(grado: Grado) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/grado', '');

    const pdata = JSON.stringify({
      entidad: {
        nombre: grado.attributes.nombre,
        codigo: grado.attributes.codigo,
        locales: [{ locale: 'en', nombre: grado.attributes.nombreEn }],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar gusto
   * @param gusto
   */
  grabarGusto(gusto: Gusto) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/gusto', '');

    const pdata = JSON.stringify({
      entidad: {
        codigo: gusto.attributes.codigo,
        descripcion: gusto.attributes.descripcion,
        locales: gusto.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarGustoIdioma(gusto: Gusto) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/gusto', '');

    const pdata = JSON.stringify({
      entidad: {
        codigo: gusto.attributes.codigo,
        descripcion: gusto.attributes.descripcion,
        locales: gusto.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar Herramienta
   * @param herramienta
   * @param ajuste
   */
  grabarHerramienta(herramienta: Herramienta, ajuste: Ajuste) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta',
      ''
    );

    const pdata = JSON.stringify({
      ajuste: {
        id: ajuste.id,
      },
      herramienta: {
        id: herramienta.attributes.id,
        orden: herramienta.attributes.orden,
        nombre: herramienta.attributes.nombre,
        tipo: herramienta.attributes.tipo,
        contenido: herramienta.attributes.contenido,
        script: herramienta.attributes.script,
        videos: {
          pre: herramienta.attributes.videoPre,
          pri: herramienta.attributes.videoPri,
          bac: herramienta.attributes.videoBac,
        },
        scripts: {
          pre: herramienta.attributes.scriptPre,
          pri: herramienta.attributes.scriptPri,
          bac: herramienta.attributes.scriptBac,
        },
        locales: herramienta.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar Herramienta
   * @param objetivo
   * @param ajuste
   */
  grabarObjetivoV2(objetivo: Objetivo, ajuste: Ajuste) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/objetivos',
      ''
    );

    const pdata = JSON.stringify({
      ajuste: {
        id: ajuste.id,
      },
      objetivo: {
        id: objetivo.attributes.id,
        nombre: objetivo.attributes.nombre,
        imagen: objetivo.attributes.imagen,
        dimension: objetivo.attributes.dimension,
        etapas: {
          pre: objetivo.attributes.etapaPre,
          pri: objetivo.attributes.etapaPri,
          bac: objetivo.attributes.etapaBac,
        },
        locales: objetivo.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarObjetivoV2Idioma(objetivo: Objetivo, ajuste: Ajuste) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/objetivos',
      ''
    );

    const pdata = JSON.stringify({
      ajuste: {
        id: ajuste.id,
      },
      objetivo: {
        id: objetivo.attributes.id,
        nombre: objetivo.attributes.nombre,
        imagen: objetivo.attributes.imagen,
        dimension: objetivo.attributes.dimension,
        etapas: {
          pre: objetivo.attributes.etapaPre,
          pri: objetivo.attributes.etapaPri,
          bac: objetivo.attributes.etapaBac,
        },
        locales: objetivo.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar Herramienta
   * @param objetivo
   * @param ajuste
   */
  grabarActividad(actividad: Actividad, objetivo: Objetivo) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'objetivo/actividad',
      ''
    );

    const pdata = JSON.stringify({
      objetivo: {
        id: objetivo.id,
      },
      actividad: {
        id: actividad.attributes.id,
        nombre: actividad.attributes.nombre,
        descripcion: actividad.attributes.descripcion,
        archivo: actividad.attributes.archivo,
        todos: actividad.attributes.todos,
        materias: actividad.attributes.materias,
        locales: actividad.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarActividadIdioma(actividad: Actividad, objetivo: Objetivo) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'objetivo/actividad',
      ''
    );

    const pdata = JSON.stringify({
      objetivo: {
        id: objetivo.id,
      },
      actividad: {
        id: actividad.attributes.id,
        nombre: actividad.attributes.nombre,
        descripcion: actividad.attributes.descripcion,
        archivo: actividad.attributes.archivo,
        todos: actividad.attributes.todos,
        materias: actividad.attributes.materias,
        locales: actividad.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarAjusteSuplente(ajustesSuplente: AjusteSuplente, ajuste: Ajuste) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/ajusteSuplente',
      ''
    );

    const pdata = JSON.stringify({
      ajuste: {
        id: ajuste.id,
      },
      ajusteSuplente: {
        id: ajustesSuplente.attributes.id,
        ajusteId: ajuste.id,
        descripcion: ajustesSuplente.attributes.descripcion,
        locales: ajustesSuplente.attributes.locales,
        imagen: ajustesSuplente.attributes.imagen,
        video: ajustesSuplente.attributes.video,
      },
    });
    console.log(pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  grabarAjusteSuplenteIdioma(ajustesSuplente: AjusteSuplente, ajuste: Ajuste) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/ajusteSuplente',
      ''
    );

    const pdata = JSON.stringify({
      objetivo: {
        id: ajuste.id,
      },
      ajusteSuplente: {
        id: ajustesSuplente.attributes.id,
        descripcion: ajustesSuplente.attributes.descripcion,
        locales: ajustesSuplente.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar herramienta
   * @param herramienta
   * @param ajuste
   */
  grabarHerramientadiomas(herramienta: Herramienta, ajuste: Ajuste) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/herramienta',
      ''
    );

    const pdata = JSON.stringify({
      ajuste: {
        id: ajuste.id,
      },
      herramienta: {
        id: herramienta.attributes.id,
        orden: herramienta.attributes.orden,
        nombre: herramienta.attributes.nombre,
        tipo: herramienta.attributes.tipo,
        contenido: herramienta.attributes.contenido,
        script: herramienta.attributes.script,
        videos: herramienta.attributes.videos,
        scripts: herramienta.attributes.scripts,
        locales: herramienta.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar herramientaind
   * @param herramientaind
   */
  grabarHerramientaind(herramientaind: Herramientaind) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind',
      ''
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      entidad: {
        codigo: herramientaind.attributes.codigo,
        contenido: herramientaind.attributes.contenido,
        nombre: herramientaind.attributes.nombre,
        tipo: herramientaind.attributes.tipo,
        videos: {
          pre: herramientaind.attributes.videoPre,
          pri: herramientaind.attributes.videoPri,
          bac: herramientaind.attributes.videoBac,
        },
        scripts: {
          pre: herramientaind.attributes.scriptPre,
          pri: herramientaind.attributes.scriptPri,
          bac: herramientaind.attributes.scriptBac,
        },
        locales: herramientaind.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarTipoTerapia(tipoTerapia: TipoTerapia) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/tipo-terapia',
      ''
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tipoTerapia.attributes.codigo,
        descripcion: tipoTerapia.attributes.descripcion,
        nombre: tipoTerapia.attributes.nombre,
        locales: tipoTerapia.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar tip
   * @param tip
   */
  grabarTip(tip: Tip) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/tip', '');
    // console.log(tip)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tip.attributes.codigo,
        nombre: tip.attributes.nombre,
        script: tip.attributes.script,
        familia: tip.attributes.familia,
        docente: tip.attributes.docente,
        imagen: tip.attributes.imagen,
        locales: tip.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar tip
   * @param tip
   */
  grabarTipIdioma(tip: Tip) {
    const url: string = this.makeDataURL('datos_basicos', 'entidad/tip', '');
    // console.log(tip)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tip.attributes.codigo,
        nombre: tip.attributes.nombre,
        script: tip.attributes.script,
        familia: tip.attributes.familia,
        docente: tip.attributes.docente,
        imagen: tip.attributes.imagen,
        locales: tip.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarObjetivo(objetivo: Objetivo, objetivoIndicadorCalif: any) {
    const url: string = this.makeDataURL('datos_basicos', 'objetivo', '');
    let dataObjetivo = {
      objetivo: {
        id: objetivo.id,
        codigo: objetivo.attributes.codigo,
        nombre: objetivo.attributes.nombre,
        dimension: objetivo.attributes.dimension,
        etapas: objetivo.attributes.etapas,
        imagen: objetivo.attributes.imagen,
        variableId: objetivo.attributes.variableId,
        codigoVariable: objetivo.attributes.codigoVariable,
        locales: objetivo.attributes.locales,
      },
    };
    if (objetivoIndicadorCalif) {
      dataObjetivo['objetivoIndicador'] = {
        calificaciones: objetivoIndicadorCalif,
        codigoVariable: objetivo.attributes.codigoVariable,
        variableId: objetivo.attributes.variableId,
      };
    }
    const pdata = JSON.stringify(dataObjetivo);
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarObjetivoIdioma(objetivo: Objetivo) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/objetivo',
      ''
    );
    const pdata = JSON.stringify({
      entidad: {
        codigo: objetivo.attributes.codigo,
        nombre: objetivo.attributes.nombre,
        dimension: objetivo.attributes.dimension,
        etapas: objetivo.attributes.etapas,
        imagen: objetivo.attributes.imagen,
        variableId: objetivo.attributes.variableId,
        codigoVariable: objetivo.attributes.codigoVariable,
        objetivoIndicador: objetivo.attributes.objetivoIndicador,
        locales: objetivo.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarAjusteObjetivo(ajuste, objetivo) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/objetivo',
      ''
    );

    const pdata = JSON.stringify({
      ajusteId: ajuste,
      objetivoId: objetivo.id,
      codigoObjetivo: objetivo.codigo,
      dimensionObjetivo: objetivo.dimension,
    });
    console.log(pdata);

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar herramientaind
   * @param herramientaind
   */
  grabarHerramientaindidiomas(herramientaind: Herramientaind) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/herramientaind',
      ''
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      entidad: {
        codigo: herramientaind.attributes.codigo,
        contenido: herramientaind.attributes.contenido,
        nombre: herramientaind.attributes.nombre,
        tipo: herramientaind.attributes.tipo,
        videos: herramientaind.attributes.videos,
        scripts: herramientaind.attributes.scripts,
        locales: herramientaind.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarTipoTerapiaidiomas(tipoTerapia: TipoTerapia) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/tipo-terapia',
      ''
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      entidad: {
        codigo: tipoTerapia.attributes.codigo,
        descripcion: tipoTerapia.attributes.descripcion,
        nombre: tipoTerapia.attributes.nombre,
        locales: tipoTerapia.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Graba los datos de una institución
   * @method grabarInstitucion_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */

  grabarInstitucion_post(institucionId: any, institucion: Institucion) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'institucion',
      ''
    );
    if (institucionId == '0') institucionId = '';
    const pdata = JSON.stringify({
      id: institucionId,
      institucion: institucion.attributes,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
        'Content-Type': 'application/json',
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar lenguaje
   * @param lenguaje
   */
  grabarLenguaje(lenguaje: Lenguaje) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/lenguaje',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: lenguaje.attributes.codigo,
        descripcion: lenguaje.attributes.descripcion,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   *
   * @param uidestudiante
   * @param uiddocente
   * @param fecha
   * @param codigo
   * @param observaciones
   */
  grabarLogro_post(
    uidestudiante: any,
    uiddocente: any,
    fecha: any,
    codigo: any,
    observaciones: any
  ) {
    const url: string = this.makeDataURL('evaluacion', 'logro', '');

    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: uiddocente,
      fecha,
      codigo,
      observaciones,
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   *
   * @param uidestudiante
   * @param uiddocente
   * @param fecha
   * @param codigo
   * @param observaciones
   */
  grabarLogroDocente_post(
    uidestudiante: any,
    uiddocente: any,
    fecha: any,
    codigo: any,
    observaciones: any
  ) {
    const url: string = this.makeDataURL('evaluacion', 'logro-doc', '');

    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: uiddocente,
      fecha,
      codigo,
      observaciones,
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   *
   */
  grabarPiar_post(uidestudiante: any, data: any) {
    const url: string = this.makeDataURL('evaluacion', 'piar', '');

    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: data.docenteId,
      fecha: data.fecha,
      administrativo: data.administrativo,
      barreras: data.barreras,
      curricular: data.curricular,
      agentes: data.agentes,
      // directivo:data.directivo,
      // docente:data.docente,
      // estudiante:data.estudiante,
      // familia:data.familia,
      // pares:data.pares,
      institucionId: data.institucionId,
    });
    console.log(pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  grabarCurricular_post(uidestudiante: any, docenteId: any, data: any) {
    const url: string = this.makeDataURL('evaluacion', 'curricular', '');

    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: docenteId,
      curricular: data,
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarPlaneacion_post(docenteId: any, data: any) {
    const url: string = this.makeDataURL('evaluacion', 'planeacion', '');
    console.log(data);
    const pdata = JSON.stringify({
      docenteId: docenteId,
      periodo: data.periodo,
      grado: data.grado,
      materia: data.materia,
      evidencias: data.evidencias,
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  generarPiar_post(uidestudiante: any, data: any) {
    const url: string = this.makeDataURL('evaluacion', 'generacionPiar', '');

    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: data.docenteId,
      fecha: data.fecha,
      tipo: data.tipo,
      institucionId: data.institucionId,
    });
    console.log(pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  grabarSeguimientoPiar_post(uidestudiante: any, data: any) {
    const url: string = this.makeDataURL('evaluacion', 'seguimiento-piar', '');
    const pdata = JSON.stringify({
      id: uidestudiante,
      docenteId: data.docenteId,
      fecha: data.fecha,
      ambiente: data.ambiente,
      situacion: data.situacion,
      aporte: data.aporte,
    });
    console.log(pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  /**
   * Grabar personalidad
   * @param personalidad
   */
  grabarPersonalidad(personalidad: Personalidad) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/personalidad',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: personalidad.attributes.codigo,
        nombre: personalidad.attributes.nombre,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar preguntaidencausas
   * @param preguntaidencausas
   */
  grabarPreguntaIdenCausas(pregunta: PreguntaIdenCausas) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/preguntaidencausas',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: pregunta.attributes.codigo,
        nombre: pregunta.attributes.nombre,
        respuestas: pregunta.attributes.respuestas,
      },
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar preocupacion
   * @param preocupacion
   */
  grabarPreocupacion(preocupacion: Preocupacion) {
    const url: string = this.makeDataURL(
      'datos_basicos',
      'entidad/preocupacion',
      ''
    );

    const pdata = JSON.stringify({
      entidad: {
        codigo: preocupacion.attributes.codigo,
        descripcion: preocupacion.attributes.descripcion,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar todos los puntajes de una categoría para un ajuste
   * @param codigoAjuste
   * @param tipo
   * @param puntajes
   */
  grabarPuntajesAjuste(
    codigoAjuste: string,
    tipo: string,
    etapa: string,
    puntajes: any
  ) {
    var arreglo: any = [];
    puntajes.forEach(p => {
      const e = {
        codigo: p.codigo,
        puntos: p.puntos,
      };
      arreglo.push(e);
    });

    const pdata = {
      ajuste: {
        codigo: codigoAjuste,
        tipo: tipo,
        etapa: etapa,
      },
      puntajes: arreglo,
    };

    const url: string = this.makeDataURL(
      'datos_basicos',
      'ajuste/puntajes',
      ''
    );
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar reto
   * @param reto
   */
  grabarReto(reto: Reto) {
    const url: string = this.makeDataURL('comunidad', 'comunidad/reto', '');
    // console.log(quiz)
    const pdata = JSON.stringify({
      evento: {
        codigo: reto.attributes.codigo,
        tema: reto.attributes.tema,
        descripcion: reto.attributes.descripcion,
        titulo: reto.attributes.titulo,
        created_by: reto.attributes.created_by,
        fecha_inicio: reto.attributes.fecha_inicio,
        fecha_fin: reto.attributes.fecha_fin,
        locales: reto.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar tema comunidad
   * @param tema
   */
  grabarTemaComunidad(tema: TemaComunidad) {
    const url: string = this.makeDataURL('comunidad', 'comunidad/tema', '');
    // console.log(quiz)
    const pdata = JSON.stringify({
      evento: {
        codigo: tema.attributes.codigo,
        descripcion: tema.attributes.descripcion,
        titulo: tema.attributes.titulo,
        estado: tema.attributes.estado,
        created_by: tema.attributes.created_by,
        locales: tema.attributes.locales,
      },
    });
    // console.log(pdata)
    // return url
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarUsuario_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarUsuario_post(datos: any) {
    const url: string = this.makeDataURL('registro_inst_est', 'usuario', '');
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar variable
   * @param variable
   */
  grabarVariable(variable: Variable) {
    const url: string = this.makeDataURL('datos_basicos', 'variable', '');
    // console.log(quiz)
    const pdata = JSON.stringify({
      variable: {
        id: variable.id,
        codigo: variable.attributes.codigo,
        descripcion: variable.attributes.descripcion,
        valores: variable.attributes.valores,
        locales: variable.attributes.locales
          ? variable.attributes.locales
          : [
              {
                locale: 'en',
                descripcion: variable.attributes.descripcionEn,
                valores: variable.attributes.valoresEn,
              },
              {
                locale: 'fr',
                descripcion: variable.attributes.descripcionEn,
                valores: variable.attributes.valoresEn,
              },
              {
                locale: 'pt',
                descripcion: variable.attributes.descripcionEn,
                valores: variable.attributes.valoresEn,
              },
              {
                locale: 'po',
                descripcion: variable.attributes.descripcionEn,
                valores: variable.attributes.valoresEn,
              },
            ],
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar variable idiomas
   * @param variable
   */
  grabarVariableIdiomas(variable: Variable) {
    const url: string = this.makeDataURL('datos_basicos', 'variable', '');
    // console.log(herramientaind)
    const pdata = JSON.stringify({
      variable: {
        id: variable.id,
        codigo: variable.attributes.codigo,
        descripcion: variable.attributes.descripcion,
        valores: variable.attributes.valores,
        locales: variable.attributes.locales,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Registroa monitoreo a estudiante
   * @param uidestudiante
   * @param uiddocente
   * @param fecha
   * @param momento
   * @param tipo
   * @param archivo
   * @param comentario
   */
  registrarMonitoreo_post(
    uidestudiante: any,
    uiddocente: any,
    fecha: any,
    momento: any,
    tipo: any,
    archivo: any,
    comentario: any,
    familia: any,
    docente: any
  ) {
    const url: string = this.makeDataURL(
      'ajustes',
      'monitoreo/registrar/',
      uidestudiante
    );
    const pdata = JSON.stringify({
      evento: {
        idSubio: uiddocente,
        fecha,
        momento,
        tipo,
        archivo,
        comentario,
        familia: familia,
        docente: docente,
      },
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Registra videoReto a docente
   * @param uiddocente
   * @param fecha
   * @param idReto
   * @param tipo
   * @param archivo
   * @param titulo
   * @param estado
   */
  registrarVideo_post(
    uiddocente: any,
    fecha: any,
    idReto: any,
    tipo: any,
    archivo: any,
    titulo: any,
    descripcion: any,
    estado: any,
    razon: any = null
  ) {
    const url: string = this.makeDataURL(
      'comunidad',
      'comunidad/video/registrar/',
      uiddocente
    );
    const pdata = JSON.stringify({
      evento: {
        idSubio: uiddocente,
        fecha,
        idReto,
        tipo,
        archivo,
        titulo: titulo,
        descripcion: descripcion,
        estado: estado,
        razon: razon,
      },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  validadInstitucion(codigoInstitucion) {
    const url: string = this.makeDataURL(
      'perfil',
      'perfiles/CodigoInstitucion/',
      codigoInstitucion
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  validarEstudianteDocumento(documentoEstudinate, idInstitucion) {
    const url: string = this.makeDataURL(
      'perfil',
      'perfiles/verEstudianteDocumento/',
      documentoEstudinate + '/' + idInstitucion
    );
    return this.http
      .get(url)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  registrarFamiliar(idEstudiante, idInstitucion, data) {
    const url: string = this.makeDataURL(
      'perfil',
      'perfiles/validarRegistrarFamilia/',
      idEstudiante + '/' + idInstitucion
    );
    // console.log(herramientaind)
    const pdata = JSON.stringify(data);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  registrarPerfil_post(uidestudiante: any, respuestasseleccionadas: any) {
    const url: string = this.makeDataURL(
      'perfil',
      'perfil/registrar/',
      uidestudiante
    );

    const pdata = JSON.stringify(respuestasseleccionadas);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  updateEstudianteAttributes(estudianteId: string, data: any) {
    // este servicio no actualiza la imagen-skalo
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'estudiante/actualizar-attributes/',
      estudianteId
    );
    const pdata = JSON.stringify({
      data: { estudiante: data },
    });
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Grabar likevideocomunidad
   * @param likevideocomunidad
   */
  grabarLikeVideo(like: Like) {
    const url: string = this.makeDataURL(
      'comunidad',
      'comunidad/like/registrar',
      ''
    );

    const pdata = JSON.stringify({
      evento: {
        idDocenteSubio: like.attributes.idDocenteSubio,
        idDocente: like.attributes.idDocente,
        fecha: like.attributes.fecha,
        idVideo: like.attributes.idVideo,
        estado: like.attributes.estado,
      },
    });

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   *
   * @param uidestudiante
   * @param respuestas
   */
  registrarPreguntasAdicionales_post(uidestudiante: any, respuestas: any) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/registrarPreguntasAdicionales/',
      uidestudiante
    );

    const pdata = JSON.stringify(respuestas);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   *
   * @param uidestudiante
   * @param respuestas
   */
  registrarPreguntasIndCausas_post(uidestudiante: any, respuestas: any) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/registrarPreguntasIndCausas/',
      uidestudiante
    );

    const pdata = JSON.stringify(respuestas);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Registra valoracion del estudiante
   * @param uidestudiante
   * @param uiddocente
   * @param respuestasseleccionadas
   */
  registrarValoracion_post(
    uidestudiante: any,
    uiddocente: any,
    respuestasseleccionadas: any,
    grado: any,
    edad: any
  ) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/registrarValoracion/',
      uidestudiante
    );

    const pdata = JSON.stringify({
      valoracion: {
        docenteId: uiddocente,
        grado: grado,
        edad: edad,
        respuestas: respuestasseleccionadas,
      },
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Registra valoracion parcial del estudiante
   * @param uidestudiante
   * @param uiddocente
   * @param respuestasseleccionadas
   */
  registrarValoracionParcial_post(
    uidestudiante: any,
    uiddocente: any,
    respuestasseleccionadas: any,
    grado: any,
    edad: any
  ) {
    const url: string = this.makeDataURL(
      'ajustes',
      'estudiante/registrarValoracionParcial/',
      uidestudiante
    );

    const pdata = JSON.stringify({
      valoracion: {
        docenteId: uiddocente,
        grado: grado,
        edad: edad,
        respuestas: respuestasseleccionadas,
      },
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  solicitarReporte_post(
    estudianteId: any,
    fecha: any,
    tipo: any,
    docente: any
  ) {
    const url: string = this.makeDataURL('evaluacion', 'reporte/solicitar', '');
    const pdata = JSON.stringify({
      id: estudianteId,
      docenteId: docente,
      tipo: tipo,
      fecha: fecha.toString(),
    });
    // console.log(JSON.parse(pdata))
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  solicitarPDF(estudianteId: any, docente: any) {
    const url: string = this.makeDataURL('evaluacion', 'reporte/generar', '');
    const pdata = JSON.stringify({
      id: estudianteId,
      docenteId: docente,
    });
    // console.log(JSON.parse(pdata))
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  actualizarReporte_post(
    estudianteId: any,
    fecha: any,
    tipo: any,
    visto: any,
    estado: any
  ) {
    const url: string = this.makeDataURL(
      'evaluacion',
      'reporte/actualizarReporte',
      ''
    );
    const pdata = JSON.stringify({
      id: estudianteId,
      docenteId: null,
      tipo: tipo,
      fecha: fecha.toString(),
      estado: estado,
      visto: visto,
    });
    // console.log(JSON.parse(pdata))
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  registrarFechaDocente_previo(usuarioId: any, tipoFecha: any) {
    if (tipoFecha == 'fecha-uso-docente') {
      var fechaUsoRegistrada =
        window.localStorage.getItem('fechaUsoRegistrada');
      if (fechaUsoRegistrada == 'Falso') {
        this.registrarFechaDocente_post(usuarioId, 'fecha-uso-docente')
          .then(res => {
            if (this.modoDebug())
              console.log('Exito, fecha-uso-docente guardada');
            window.localStorage.setItem('fechaUsoRegistrada', 'Verdadero');
          })
          .catch(async err => {
            if (this.modoDebug())
              console.log('Error guardando fecha-uso-docente');
          });
      }
    } else if (tipoFecha == 'fecha-monitoreo-docente') {
      var fechaMonitoreoRegistrada = window.localStorage.getItem(
        'fechaMonitoreoRegistrada'
      );
      if (fechaMonitoreoRegistrada == 'Falso') {
        this.registrarFechaDocente_post(usuarioId, 'fecha-monitoreo-docente')
          .then(res => {
            if (this.modoDebug())
              console.log('Exito, fecha-monitoreo-docente guardada');
            window.localStorage.setItem(
              'fechaMonitoreoRegistrada',
              'Verdadero'
            );
          })
          .catch(async err => {
            if (this.modoDebug())
              console.log('Error guardando fecha-monitoreo-docente');
          });
      }
    }
  }

  registrarFechaDocente_post(usuarioId: any, tipoFecha: any) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'fechadocente',
      ''
    );
    const pdata = JSON.stringify({
      usuarioId: usuarioId,
      tipoFecha: tipoFecha,
    });
    // console.log(JSON.parse(pdata));
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  registrarSolicitudTerapeuta(
    estudianteId: any,
    docenteId: any,
    email: any,
    tipoTerapia: any,
    notaAlTerapeuta: any,
    idioma: any
  ) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'recomendacionterapeuta',
      ''
    );
    const pdata = JSON.stringify({
      data: {
        docenteId: docenteId,
        estudianteId: estudianteId,
        email: email,
        estado: 'Sin responder',
        tipoTerapia: tipoTerapia,
        notaAlTerapeuta: notaAlTerapeuta,
        idioma: idioma,
        // createdAt y updatedAt disponibles en data
      },
    });
    console.log(JSON.parse(pdata));
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  actualizarFotoPost(estudianteID: any, imagen: any) {
    const url: string = this.makeDataURL(
      'registro_inst_est',
      'actualizarFoto',
      ''
    );
    const pdata = JSON.stringify({
      estudianteId: estudianteID,
      nombreImagen: imagen,
    });
    // console.log(JSON.parse(pdata));
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  autoregistroDocenteComunidad(v_username: any) {
    const url: string = this.makeDataURL(
      'comunidad',
      'docenteautoregistrocomunidad',
      ''
    );
    const pdata = JSON.stringify({
      //"id": v_username
      id: v_username,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
}

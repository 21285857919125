import { ApiCommonService } from './api-common.service';
import { HttpClient } from '@angular/common/http';
import { Institucion } from 'src/app/models/institucion';
import { Injectable } from '@angular/core';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class ApiEnrolamientoService {
  constructor(
    private common: ApiCommonService,
    private http: HttpClient,
    private user: UserService
  ) {}

  public modoDebug() {
    return this.common.modoDebug();
  }

  /**
   * Asocia un usuario a una institucion por su email
   * @method grabarInstitucion_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */

  asociarUsuarioAdmin(institucionId: any, email: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion/admin',
      ''
    );
    const pdata = JSON.stringify({
      id: institucionId,
      email: email,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
        'Content-Type': 'application/json',
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  cambiarContrasenaUsuario(datos) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/cambiarcontrasena',
      ''
    );
    const pdata = JSON.stringify(datos);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  fijarContrasenaObligatoriaUsuario(datos) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/fijarcontrasena',
      ''
    );
    const pdata = JSON.stringify(datos);
    // const options = {
    //   headers: {
    //     Authorization: this.user.getToken(),
    //     'Content-Type': 'application/json'
    //   }
    // };
    //console.log(pdata);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEsDocenteFamiliarOAdmin(userId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/esdocentefamiliaroadmin/',
      userId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEstudiante(estudianteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/',
      estudianteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEstudianteRelaciones(estudianteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/relaciones/',
      estudianteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getEsUsuarioSkaloAdmin(userId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/esusuarioskaloadmin/',
      userId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarEstudiantesInstitucion(institucionId, lastKey) {
    if (lastKey != '0') {
      lastKey = JSON.stringify(lastKey);
    }
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/institucion/',
      institucionId + '/' + lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarEstudiantesInstitucionExport(institucionId, lastKey) {
    if (lastKey != '0') {
      lastKey = JSON.stringify(lastKey);
    }
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/institucionExport/',
      institucionId + '/' + lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarFechasDocente(docenteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'fechasdocente/',
      docenteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarInstituciones(lastKey = '0') {
    if (lastKey != '0') {
      if (typeof lastKey !== 'string') {
        lastKey = JSON.stringify(lastKey);
      }
    }
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'instituciones/usuario/',
      lastKey
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getListarUsuariosInstitucion(institucionId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuarios/institucion/',
      institucionId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getLoginDocente(docenteId) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'docente/loginregister/',
      docenteId
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getObtenerEstudiante(codigo: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudianteCodigo/',
      codigo
    );
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getObtenerUsuario(codigo: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuarioCodigo/',
      codigo
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  getUsuarioActual() {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/actual',
      ''
    );
    let token = this.user.getToken();
    const options = {
      headers: {
        Authorization: token,
      },
    };
    return this.http
      .get(url, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(async () => {
        this.common.handleError;
        if (token) {
          // token vencido
          await this.user.logout();
          window.location.reload();
        }
      });
  }

  /**
   * Registro primer login docente
   * @param iddocente
   */
  registrarLoginDocente(iddocente: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'docente/loginregister',
      ''
    );
    const pdata = JSON.stringify({
      docente: {
        id: iddocente,
      },
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };

    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarAsignarFamilar_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarAsignarFamiliar(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante/familiar',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarAsignarDocente_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarAsignarDocente(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'imagen-skalo/docente',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarEstudiante_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarEstudiante(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiante',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    if (this.common.modoDebug()) console.log(pdata);
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar un conjunto de estudiantes importados desde Excel para una institución
   */
  grabarEstudiantesBatch(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/grabar-batch',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarInstitucion_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */

  grabarInstitucion(institucionId: any, institucion: Institucion) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'institucion',
      ''
    );
    if (institucionId == '0') institucionId = '';
    const pdata = JSON.stringify({
      id: institucionId,
      institucion: institucion.attributes,
    });
    const options = {
      headers: {
        Authorization: this.user.getToken(),
        'Content-Type': 'application/json',
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de invitación para un nuevo usuario
   * @method grabarInvitacion
   * @data {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarInvitacion(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/invitar',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar un conjunto de estudiantes importados desde Excel para una institución
   */
  grabarRelacionesBatch(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'estudiantes/grabar-relaciones-batch',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Graba los datos de una institución
   * @method grabarUsuario_post
   * @formdata {any} formulario de registro de pista
   * @return {Promise}
   */
  grabarUsuario(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/cambiadatosusuario',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Grabar un conjunto de usuarios importados desde Excel para una institución
   */
  grabarUsuariosBatch(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/grabar-batch',
      ''
    );
    const pdata = JSON.stringify(datos);
    const options = {
      headers: {
        Authorization: this.user.getToken(),
      },
    };
    return this.http
      .post(url, pdata, options)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Login usando Cognito API
   */
  loginUsuario(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/login',
      ''
    );
    const pdata = JSON.stringify(datos);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Recuperar contraseña usando Cognito API
   */
  recuperarContrasena(email: string) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/recuperarcontrasena',
      ''
    );
    const pdata = JSON.stringify({
      email: email,
    });
    //console.log(pdata);

    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }

  /**
   * Reset contraseña usando Cognito API
   */
  resetContrasenaUsuario(datos: any) {
    const url: string = this.common.makeDataURL(
      'registro_inst_est',
      'usuario/resetcontrasena',
      ''
    );
    const pdata = JSON.stringify(datos);
    return this.http
      .post(url, pdata)
      .toPromise()
      .then(this.common.extractData)
      .catch(this.common.handleError);
  }
}

import { ApiService } from './services/api.service';
import { CacheService } from 'ionic-cache';
import { Config, MenuController, Platform } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public isauthenticated = false;
  public isopened = false;
  public selectedIndex = 0;

  private usuario: any = null;
  email: any;
  nombre: any;
  foto: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private user: UserService,
    public config: Config,
    private translate: TranslateService,
    public cache: CacheService,
    private menuCtrl: MenuController,
    private api: ApiService
  ) {
    cache.setDefaultTTL(60 * 60); // set default cache TTL for 1 hour
    this.initializeApp();
  }

  closemenu() {
    this.isopened = false;
    this.menuCtrl.close().then(data => {
      // console.log("menuskalo"  + JSON.stringify(data));
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.setDefaultLang('es');
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {}

  ionViewDidEnter() {}

  logout() {
    this.user.logout();
    this.menuCtrl.close().then(data => {
      //window.location.assign(environment.logout);
      this.router.navigate(['/']);
      window.location.reload();
    });
  }
}
